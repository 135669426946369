export const launchDarklyFlagVariants = {
    CRO_911_OFFER_PAGE_HEADING: {
        VARIANT_A: {
            title: 'We’ll find you the best deals on Airport Parking',
        },
        VARIANT_B: {
            title: 'Compare & Book Airport Parking in Seconds',
        },
        VARIANT_C: {
            title: 'Book with Confidence – Secure & Affordable Airport Parking',
        },
    },
};
